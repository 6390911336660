import React from "react"

import Layout from "../layouts/layout"
import Seo from "../components/seo"

import Text from "../components/text"
import Headline from "../components/headline"
import Block from "../components/block"
import Logo from "../components/logo"

const ImpressumPage = () => (
  <Layout>
    <Seo title="Imprint" />

    <Block>
      <Logo />
      <Headline text="Imprint" primary />
    </Block>

    <Block id="text">
      <Text left>
        <p>verantwortlich für den Inhalt:</p>
        <p>
          Hagen Hansen
          <br />
          Paetowweg 6<br />
          18347 Ostseebad Ahrenshoop
          <br />
          Deutschland
          <br />
          Telefon: <a href="tel:+49382206677-0">+49 38220 6677-0</a>
          <br />
          Telefax: +49 38220 6677-22
          <br />
          E-Mail:{" "}
          <a href="mailto:info@hagenhansen.de">info [at] hagenhansen.de</a>
        </p>
        <p>USt-IdNr. DE179277919</p>
        <p>
          <a href="/privacy">Privacy</a>
        </p>
        <p>
          Bild und Textbeiträge dieser Website sind urheberrechtlich geschützt
          und Eigentum von Hagen Hansen. Die Weiterverwendung jeglicher Inhalte
          ist untersagt. Keine Abmahnung ohne vorherigen Kontakt. Sollte der
          Inhalt oder die Aufmachung dieser Seiten fremde Rechte Dritter oder
          gesetzliche Bestimmungen verletzen, so bittet Hagen Hansen um eine
          entsprechende Nachricht ohne Kostennote. Er garantiert, dass die zu
          Recht beanstandeten Passagen unverzüglich entfernt werden, ohne dass
          von Ihrer Seite die Einschaltung eines Rechtsbeistandes erforderlich
          ist. Dennoch von Ihnen ohne vorherige Kontaktaufnahme ausgelöste
          Kosten werden vollumfänglich zurückgewiesen.
        </p>
      </Text>
    </Block>
  </Layout>
)

export default ImpressumPage
